:root {
  --header-padding-top: 1.5em;
  --header-padding-bottom: 0.60em;
  --wild-west-brown: #5B493B;
  --wild-west-blue: #3B4D5B;
  --wild-west-granite:#909BA3;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.plr-1 {
  padding-left: 2rem!important;
  padding-right: 2rem!important;
}

footer a {
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wild-west-brown-bg {
  background: var(--wild-west-brown);
}


.services-header, .team-header, .footer-header, .about-header {
  padding-top: var(--header-padding-top);
  padding-bottom: var(--header-padding-bottom);
}


nav > *, .nav-link   {
  color: white!important;
}
.navbar-header {
  margin-right: 0!important;
  color: white!important;
}

.header-icon {
  max-width: 40px;
  max-height: 60px;
}

.header-section > picture {
  height: inherit;
}

.header-image {
  width: 100%;
  position: absolute;
  height: inherit!important;
}

.header-section {
  height: 35vh;
  padding: 0!important;
}

.header-overlay {
  height: 100%;
  width: 100%;
  background: black;
  opacity: 25%;
}

.about-section {
  padding-bottom: 2rem;
}

.about-paragraph, .about-subtext {
  padding: 1rem;
}


.team-member-image {
  max-height: 70vh;
}

.services-section {
  padding-bottom: 4rem;
}

.carousel-image {
  height: 65vh;
  object-fit: fill;
}

.carousel-overlay {
  background: black;
  height: 100%;
  position: absolute;
  width: 100%;
  opacity: 60%;
}

footer {
  color: white;
  background-color: var(--wild-west-blue);
}

.contact-form-container {
  padding: 0rem!important;
}

.contact-form-button {
  margin-top: 1rem;
  width: 80%; 
}

.contact-info-section {
  padding: 0px 2rem 1rem 2rem!important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.contact-info-section > *> p {
    margin-bottom: 0!important;
}

.contact-form-section {
  padding-left: 0rem!important;
  padding-right: 0rem!important;
}

.top-page-arrow {
  height: 15vh;
  width: 15vw;
  position: sticky;
  bottom: 0px;
  margin-left: auto;
  margin-right: 5vw;
}

.arrow-icon {
  height: 100%;
  width: 100%;
}


/*End mobile and up styles*/
/*===============MOBILE LARGE===============*/
@media only screen and (min-width: 425px) and (max-width: 575px) {

  .contact-form-container {
    padding: 1rem!important;
  }

}

/*===============TABLET AND UP===============*/
@media only screen and (min-width: 768px) {

  .header-section {
    height: 45vh;
  }

  .about-paragraph, .about-subtext {
    padding: 0rem 3rem 3rem 3rem;
    /* font-size: 1rem; */
  }

}/*end media query*/
/*End tablet and up styles*/
/*===============DESKTOP ONLY===============*/
@media only screen and (min-width: 992px) {

  .header-section {
    height: 55vh;
  }

  .about-section {
    padding: 5rem 0rem 6rem 6rem;
  }

	.about-paragraph {
    padding: 0rem 6rem 3rem 6rem;
    font-size: 1.25rem;
  }

  .about-subtext {
    padding: 0rem 6rem 3rem 6rem;
  }

  .contact-form-section {
    padding-left: 2rem!important;
    padding-right: 2rem!important;
  }
  
  .carousel {
    width: 90%;
    margin: auto;
  }

  .carousel-image {
    height: 65vh;
  }
  
}/*end media query*/
/*end desktop only styles*/